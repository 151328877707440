// kendo variables
$kendo-is-dark-theme: false;
$kendo-color-primary: #00b2a9;
$kendo-color-secondary: #8f9da9;
$kendo-color-info: #0dcaf0;
$kendo-color-success: #198754;
$kendo-color-warning: #ffc107;
$kendo-color-error: #dc3545;
$kendo-body-text: #212529;
$kendo-body-bg: #ffffff;
$kendo-subtle-text: #6c757d;
$kendo-disabled-text: #8f8f8f;
$kendo-component-text: $kendo-body-text;
$kendo-component-bg: $kendo-body-bg;
$kendo-base-text: $kendo-body-text;
$kendo-base-bg: #f8f9fa;
$kendo-hover-text: #16181b;
$kendo-hover-bg: #e9ecef;
$kendo-selected-text: #ffffff;
$kendo-selected-bg: $kendo-color-primary;
$kendo-button-text: $kendo-body-text;
$kendo-button-bg: #e4e7eb;
$kendo-link-text: $kendo-color-primary;
$kendo-link-hover-text: darken($kendo-link-text, 10%);
$kendo-series-a: #eaaa00;
$kendo-series-b: #e03c31;
$kendo-series-c: #e782a9;
$kendo-series-d: #8031a7;
$kendo-series-e: #407ec9;
$kendo-series-f: #00b2a9;

$wcag-min-contrast-ratio: 2.64;


// bootstrap variables
$primary: $kendo-color-primary;
$secondary: $kendo-color-secondary;
$success: $kendo-color-success;
$info: $kendo-color-info;
$warning: $kendo-color-warning;
$danger: $kendo-color-error;
$body-bg: $kendo-body-bg;
$body-color: $kendo-body-text;
$link-color: $kendo-link-text;
$link-hover-color: $kendo-link-hover-text;

// card
$card-cap-bg: rgba(0, 0, 0, 0.01);

$min-contrast-ratio: $wcag-min-contrast-ratio;
