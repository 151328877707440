/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import '@progress/kendo-theme-bootstrap/scss/all';
@import 'bootstrap/scss/bootstrap';

@font-face {
  font-family: 'roboto-medium';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-light';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}

html, body {
  height: 100vh;
  font-family: 'roboto-light', sans-serif;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'roboto-medium', sans-serif;
}

h1, h2 {
  color: $secondary;
}

.button-primary {
  border-radius: var(--bs-border-radius-lg);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-xl {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.card-configured {
  background-color: rgba($primary, 0.15);
}
